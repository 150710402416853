<template>
  <div>
    <div class="countdown-wrapper">
      <vac :end-time="endTime"
           @finish="startLive">
        <span slot="process"
              slot-scope="{ timeObj }">
          <b>{{ scheduled.name }}</b>
          {{ ` will start in ${timeObj.h}:${timeObj.m}:${timeObj.s}` }}
        </span>
        <span slot="finish">Times Up!</span>
      </vac>
    </div>
    <!-- Dialog -->
    <v-dialog/>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment-timezone';

  export default {
    name : 'CountdownTimer',
    data () {
      return {
        scheduled : null,
        endTime   : 0,
        userTZ    : this.$store.getters['user/user'].timezone,
      }
    },
    computed : {
      ...mapGetters({
        scheduledLiveBroadcast : 'broadcast/scheduledLiveBroadcast',
      }),
    },
    watch : {
      '$store.state.broadcast.scheduledLiveBroadcast.result'() {
        this.scheduled =
          this.$store.state.broadcast.scheduledLiveBroadcast.result;
      },
    },
    methods : {
      ...mapActions({
        getScheduledLiveBroadcast : 'broadcast/getScheduledLiveBroadcast',
      }),

      /**
       * Start Live
       */
      startLive() {
        this.$http.post('api/live_broadcast/set', {
          liveBroadcastId : this.scheduled.live_broadcast_id,
          action          : 'set',
        }).then(() => {
          this.$modal.hide('dialog');
          this.getScheduledLiveBroadcast();
          this.$emit('update-countdown');
          this.$notify({
            group : 'notif',
            type  : 'success',
            title : 'Success!',
            text  : 'Set ' + this.scheduled.name + ' as live!',
          });
        }).catch(() => {
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong!',
          });
        });
      },
    },
    mounted() {
      this.scheduled = this.scheduledLiveBroadcast.result;

      // set default moment time zone to user's time zone
      moment.tz.setDefault(this.userTZ);

      // convert start time in user's time zone to local time zone
      const liveTime = moment(this.scheduled.start_time_user_tz).local();
      this.endTime = new Date(liveTime).getTime();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/notification/countdown-timer";
</style>
